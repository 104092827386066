export default {
	name: "WarrantMovers",
	created () {
		this.$nextTick(function () {
			// 获取 Warrant Movers 的数据
			this.WarrantMoversGetMarketData('topwarrant', 0)
			this.MonitorScreen()
		});
	},
	mounted () {
		this.$nextTick(function () {
			// this.$$.navbarAnimation()
		})
	},
	data () {
		return {
			WarrantMoversData: [],
			JudgeIos: true,
			dataName: 'topwarrant',
			bidstatus: 0
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取 Warrant Movers 的数据
		WarrantMoversGetMarketData: function (value, bidstatus) {
			$.ajax({
				type: "POST",
				dataType: 'json',
				url: this.$$.mibb + "GetMarketData",
				data: { token: "webkey", type: 'ranking', bidstatus: bidstatus },
				success: (res) => {
					if (res.status == 'SUCCESS') {
						this.WarrantMoversData = res[value]
					}
				},
				error: function (XMLHttpRequest) { }
			});
		},
		// 点击按钮 接受数据更改表格数据
		getDatum: function (index, value) {
			if (value == 'topwarrant') {
				$('#WarrantMovers').find('.ButBox').find('.form-check-input').attr('disabled', 'disabled')
			} else {
				$('#WarrantMovers').find('.ButBox').find('.form-check-input').removeAttr('disabled')
			}
			$('#WarrantMovers').find('.btn-group').find('button').eq(index).addClass('mark')
			$('#WarrantMovers').find('.btn-group').find('button').eq(index).siblings().removeClass('mark')
			this.dataName = value
			this.WarrantMoversGetMarketData(value, 0)
		},
		changeData: function (dataName, e) {
			if (e.target.checked) {
				this.bidstatus = 1
			} else {
				this.bidstatus = 0
			}
			this.WarrantMoversGetMarketData(dataName, this.bidstatus)
		},
		// 监听屏幕
		MonitorScreen: function () {
			if ($(window).width() <= 992) {
				this.JudgeIos = false
			} else {
				this.JudgeIos = true
			}
			$(window).resize(() => {
				if ($(window).width() <= 992) {
					this.JudgeIos = false
				} else {
					this.JudgeIos = true
				}
			})
		}
	}
};